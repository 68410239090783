<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>企業名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  v-maxlength
                                  maxlength="255"
                                  name="name"
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-if="isGmo || (isClientParent && useTicketFlag)">
                    <div class="row">
                      <div v-if="isGmo" class="col">
                        <FormRow>
                          <template v-slot:label>親企業名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="parentId"
                                  v-model="searchForm.parentId"
                                >
                                  <option value="">選択してください</option>
                                  <option
                                    v-for="item in companyList"
                                    :key="item.id"
                                    :value="item.id"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div v-if="useTicketFlag" class="col">
                        <FormRow>
                          <template v-slot:label>TICKET名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select class="form-control form-select-input" name="ticketId" v-model="searchForm.ticketId">
                                  <option value="">選択してください</option>
                                  <option v-for="item in ticketList" :key="item.id" :value="item.id.toString()">{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>期間指定</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-group">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="startDate"
                                      v-model="searchForm.startDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="endDate"
                                      v-model="searchForm.endDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button" @click="resetSearch">リセット</button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button" @click="search">検索</button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay position="center" color="dark">
                  <template v-slot:title>企業発行金額</template>
                  <template v-slot:num>{{ companyChildEventAggregateTotal | comma }}</template>
                  <template v-slot:sup>円</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :data-table="dataTable"
                    :labels="labels"
                    :storeModule="storeModule"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="pagination"
                  :listLength="companyChildEventAggregateCount"
                  :modulePath="modulePath"
                  :searchConditions="searchConditions"
                  :noGetPaginationList="noGetPaginationList"
                />
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ActionButton :disabled="isDisableDownloadCsv || isDisable" class="btn btn-black" :handle-submit="hanldeClickDownload" button-text="CSVダウンロード" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <ModalConfirmDownload
      v-if="downloadCSVModal"
      @cancelDownloadCsv="cancelDownloadCsv"
      @acceptDownloadCsv="acceptDownloadCsv"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Flatpickr from 'vue-flatpickr-component';
//mixin
import flatpickr from '@/mixins/plugin/flatpickr';
import sort from '@/mixins/plugin/sort';
import search from '@/mixins/plugin/search';
import download from '@/mixins/plugin/download';
import history from '@/mixins/module/history';
import nav from '@/mixins/nav/summary';
import company from '@/mixins/module/company';
//component
import FormRow from '@/components/FormRow.vue';
import NumDisplay from '@/components/NumDisplay.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import ModalConfirmDownload from '@/components/ModalConfirmDownload'
//helpers
import { formatLocalString, addThreeDotEndLine, endline } from '@/helpers/formatData';

export default {
  name: 'EventSummaryCompany',
  data: function() {
    return {
      pageName: '集計',
      searchConditions: {},
      searchFields: ['name', 'parentId', 'startDate', 'endDate', 'ticketId'],
      normalModulePath: 'aggregate/getCompanyChildEventAggregate',
      ticketModulePath: 'aggregate/getCompanyChildEventAggregateTicket',
      normalCSVPath: 'aggregate/downloadCompanyAggregateList',
      ticketCSVPath: 'aggregate/downloadCompanyAggregateListTicket',
      storeModule: 'aggregate',
      storedField: 'idRequestExportCompanySummary'
    };
  },
  components: {
    FormRow,
    NumDisplay,
    Flatpickr,
    TableWithCheckbox,
    PaginationLog,
    ModalConfirmDownload,
  },
  mixins: [nav, flatpickr, sort, search, download, company, history],
  computed: {
    ...mapGetters({
      companyChildEventAggregate: 'aggregate/companyChildEventAggregate',
      companyChildEventAggregateTotal: 'aggregate/companyChildEventAggregateTotal',
      companyList: 'client/clientList',
      useTicketFlag: 'event/useTicketFlag',
      ticketList: 'history/ticketList',
      isClientParent: 'auth/isClientParent',
    }),
    companyChildEventAggregateCount() {
      return this.companyChildEventAggregate?.count;
    },
    isDisableDownloadCsv() {
      return !this.companyChildEventAggregate?.list?.length;
    },
    isGmo() {
      return this.$permission.isGmo();
    },
    subdomain() {
      return this.$route.params.subdomain;
    },
    labels() {
      if (this.useTicketFlag) {
        return [
          { key: 'companyName', name: '企業名', tdClass: 'pre-line' },
          { key: 'ticketName', name: 'TICKET名', tdClass: 'pre-line' },
          { key: 'quantityCharge', name: '発行枚数' },
          { key: 'amountCharge', name: '発行金額' },
          { key: 'quantityCancel', name: '取消枚数' },
          { key: 'amountCancel', name: '取消金額' },
          { key: 'quantityPayment', name: '利用済枚数' },
          { key: 'amountPayment', name: '利用済金額' },
          { key: 'quantityExpired', name: '失効枚数' },
          { key: 'amountExpired', name: '失効金額' },
        ];
      }
      return [
        { key: 'name', name: '企業名' },
        { key: 'times', name: '発行回数' },
        { key: 'amount', name: '発行金額' },
        { key: 'cancel', name: '取消額' },
        { key: 'used', name: '利用済金額' },
        { key: 'expired', name: '失効金額' },
      ];
    },
    dataTable() {
      return this.companyChildEventAggregate?.list?.map((item) => {
        if (this.useTicketFlag) {
          return {
            companyName: endline(item.companyName, 10),
            ticketName: endline(item.ticketName, 10),
            amountCharge: '¥' + formatLocalString(item.amountCharge),
            quantityCharge: item.quantityCharge,
            amountCancel: '¥' + formatLocalString(item.amountCancel),
            quantityCancel: item.quantityCancel,
            amountPayment: '¥' + formatLocalString(item.amountPayment),
            quantityPayment: item.quantityPayment,
            amountExpired: '¥' + formatLocalString(item.amountExpired),
            quantityExpired: item.quantityExpired,
          }
        }
        return {
          name: addThreeDotEndLine(item.name),
          times: item.times,
          amount: '¥' + formatLocalString(item.added),
          cancel: '¥' + formatLocalString(item.cancelledBalance),
          used: '¥' + formatLocalString(item.usedBalance),
          expired: '¥' + formatLocalString(item.expiredBalance)
        };
      }) || [];
    },
    AggregateTotalAction() {
      if (this.noGetPaginationList) return '';
      return this.useTicketFlag ? 'aggregate/getCompanyChildEventAggregateTotalTicket' : 'aggregate/getCompanyChildEventAggregateTotal'
    }
  },
  methods: {
    async hanldeClickDownload() {
      this.isDisable = true;
      const result = await this.$store.dispatch(this.downloadCSVPath, {
        ...this.payloadDownloadCsv,
        subdomain: this.subdomain,
      });
      if (result) {
        this.saveIdRequestExport(result.id);
        this.checkExportCsvStatus(result.id);
      }
    },
  },
  async mounted() {
    this.getTicketList(),
    await this.$store.dispatch('event/getParentEventDetail', this.$route.params.subdomain);
    this.$store.dispatch(this.AggregateTotalAction);
  },
  watch: {
    useTicketFlag(value){
      if (value) {
        this.getTicketList();
        this.$store.dispatch(this.AggregateTotalAction);
      }
    }
  }
};
</script>
